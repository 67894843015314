import React from "react"
import "./styles.scss"

const References = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title">
          <h2>References -</h2>
        </div>
      </div>
      <ReferenceItem
        referencename="Don Juan Matus"
        jobposition="Job Position | Company Name"
        referencenumber="phone: +52 84219944"
        email="soy@israelortiz.com"
      />
      <ReferenceItem
        referencename="Don Juan Matus"
        jobposition="Job Position | Company Name"
        referencenumber="phone: +52 84219944"
        email="soy@israelortiz.com"
      />
      <ReferenceItem
        referencename="Don Juan Matus"
        jobposition="Job Position | Company Name"
        referencenumber="phone: +52 84219944"
        email="soy@israelortiz.com"
      />
    </div>
  )
}

const ReferenceItem = ({ referencename, jobposition, referencenumber, email }) => {
  return (
    <div className="col-lg-12">
      <p className="reference-name mb-0">{referencename}</p>
      <p className="job-position">{jobposition}</p>
      <a className="reference-number mb-0" href={`tel: ${referencenumber}`}>
        {referencenumber}
      </a>
      <a className="mb-0" href={`mailto: ${email}`}>
        {email}
      </a>
      <br />
    </div>
  )
}

export default References
