import React from "react"
import MainContent from "./components/main_content"
import Sidebar from "./components/sidebar"
import "bootstrap/dist/css/bootstrap.min.css"
import "./styles/App.scss"

function App() {
  return (
    <>
        <div className="main">
          <Sidebar />
          <MainContent />
        </div>
    </>
  )
}

export default App;
