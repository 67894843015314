import React from "react"
// icons
import { GiMailbox, GiSmartphone } from "react-icons/gi"
import { FaGithub } from "react-icons/fa"
import { BiLocationPlus } from "react-icons/bi"
import { AiFillLinkedin } from "react-icons/ai"
import { MdWebAsset } from "react-icons/md"
// styles
import "./styles.scss"

const InfoElements = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title mt-30">
          <h2>You can find me here -</h2>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <InfoElement title="MAIL" info="soy@israelortiz.com" url="mailto: soy@israelortiz.com">
          <GiMailbox className="icon-element" />
        </InfoElement>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <InfoElement title="PHONE" info="+52 84-21-9944" url="tel: +52 8125-221710">
          <GiSmartphone className="icon-element" />
        </InfoElement>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <InfoElement
          title="ADDRESS"
          info="inca pte 123, México"
          url="https://www.google.com/maps/@25.6913843,-100.2487974,15z">
          <BiLocationPlus className="icon-element" />
        </InfoElement>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <InfoElement title="WEBPAGE" info="https://israelortiz.com" url="https://israelortiz.com">
          <MdWebAsset className="icon-element" />
        </InfoElement>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <InfoElement
          title="GITHUB"
          info="https://github.com/binaryme"
          url="https://github.com/binaryme">
          <FaGithub className="icon-element" />
        </InfoElement>
      </div>
      <div className="col-lg-4">
        <InfoElement
          title="LINKEDIN"
          info="https://www.linkedin.com/in/binary"
          url="https://www.linkedin.com/in/israel-ortiz-449b44b9">
          <AiFillLinkedin className="icon-element" />
        </InfoElement>
      </div>
    </div>
  )
}

const InfoElement = ({ title, info, children, url }) => {
  return (
    <div className="info-element">
      {children}
      <div className="data-element">
        <h2>{title}</h2>
        <a href={url}>{info}</a>
      </div>
    </div>
  )
}

export default InfoElements
