import React from "react"
import "./styles.scss"

const Skills = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title">
          <h2>Skills -</h2>
        </div>
      </div>
      <SkillItem date="2015 - present" title="React.js Library" />
      <SkillItem date="2015 - present" title="React.js Library" />
      <SkillItem date="2015 - present" title="React.js Library" />
      <SkillItem date="2015 - present" title="React.js Library" />
    </div>
  )
}

const SkillItem = ({ date, title }) => {
  return (
    <div className="col-lg-12">
      <p className="date">{date}</p>
      <p className="glow-title mb-30">{title}</p>
    </div>
  )
}

export default Skills
