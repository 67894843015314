import React from "react"
import "./styles.scss"

const EmploymentHistory = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title mt-60">
          <h2>Employment History -</h2>
        </div>
      </div>
      <HistoryUnit
        date="2015 - Present"
        jobposition="Job Position / Title Here"
        companyname="Company Name | Institute Name | Location">
        <p>Enter a short and concise description of what you did in this role.</p>
      </HistoryUnit>
      <HistoryUnit
        date="2015 - Present"
        jobposition="Job Position / Title Here"
        companyname="Company Name | Institute Name | Location">
        <p>Enter a short and concise description of what you did in this role.</p>
      </HistoryUnit>
      <HistoryUnit
        date="2015 - Present"
        jobposition="Job Position / Title Here"
        companyname="Company Name | Institute Name | Location">
        <p>Enter a short and concise description of what you did in this role.</p>
      </HistoryUnit>
    </div>
  )
}

const HistoryUnit = ({ date, jobposition, companyname, children }) => {
  return (
    <div className="col-lg-12">
      <div className="employment">
        <p className="date">{date}</p>
        <p className="job-position">{jobposition}</p>
        <p className="company-name">{companyname}</p>
        <hr />
        {children}
        <br />
        <br />
      </div>
    </div>
  )
}

export default EmploymentHistory
