import React from "react"
import "./styles.scss"

const Sidebar = () => {
  return (
    <div className="sidebar">
      <SidebarHeader />
    </div>
  )
}

const SidebarHeader = () => {
  return (
    <div className="sidebar-header">
      <img
        src="/img/profile-astral-cat.jpg"
        className="img-fluid img-round img-profile"
        alt="profile"
      />
      <h1>ISRAEL ORTIZ BINARY</h1>
      <h2>SOFTWARE DEVELOPER</h2>
      <div className="division" />
      <p>
        I am a full-stack software engineer with extensive experience building web applications from
        scratch and coordinating agile teams.
      </p>
    </div>
  )
}

export default Sidebar
