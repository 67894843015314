import React from "react"
// components
import InfoElements from "../info_elements"
import EmploymentHistory from "../employment_history"
import References from "../references"
import Skills from "../skills"

import "./styles.scss"

const MainContent = () => {
  return (
    <div className="header">
      <div className="container-fluid">
        <InfoElements />
        <EmploymentHistory />
        {/* here we demonstrate how with bootstrap it is very easy to create new and creative layouts based on this architecture */}
        <div className="row">
          <div className="col-lg-6">
            <References />
          </div>
          <div className="col-lg-6">
            <Skills />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainContent
